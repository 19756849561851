import React from "react";
import { useEffect, useState } from "react";
import {text} from './promotion.module.css'

function Promotion({ month }) {
  const [moisPromo, setMoisPromo] = useState("");
  useEffect(() => {
    switch (month) {
      case 2:
        setMoisPromo("de Mars");
        break;
      case 5:
        setMoisPromo("de Juin");
        break;
      case 9:
        setMoisPromo("d'Octobre");
        break;

      default:        
        break;
    }
  }, [month]);

  
  return (
    <div className={text}>
      <p>
        <strong>Promotion</strong> <span>jusqu'à -30%</span>* à partir du 1er Samedi du mois {moisPromo} !!! *Nous contacter au <a href="tel:+33626762650">06.26.76.26.50</a> pour les conditions
      </p>
    </div>
  );
}

export default Promotion;
